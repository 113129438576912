import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['label', 'options', 'dropdown', 'selected'];

  initialize() {
    this.handleClickOutside = this.close.bind(this);
    document.addEventListener('click', this.handleClickOutside);
  }

  toggle() {
    this.optionsTarget.classList.toggle('hidden');
    this.dropdownTarget.classList.toggle('open');
  }

  select(event) {
    event.stopPropagation();
    const selectedOption = event.currentTarget;
    this.labelTarget.textContent = selectedOption.textContent;
    this.labelTarget.setAttribute(
      'data-value',
      selectedOption.getAttribute('data-value')
    );
    this.selectedTarget.value = selectedOption.getAttribute('data-value');
    this.optionsTarget.classList.add('hidden');
    this.dropdownTarget.classList.remove('open');
  }

  close(event) {
    if (!this.dropdownTarget.contains(event.target)) {
      this.optionsTarget.classList.add('hidden');
      this.dropdownTarget.classList.remove('open');
    }
  }

  disconnect() {
    document.removeEventListener('click', this.handleClickOutside);
  }
}
